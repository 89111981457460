 
//第一步导入及地址
import request from '@/utils/request'
 


// 查看全部演员
export function performerpage (){
    return request({
        url:`/sys/sysperformer/list?deptId=1067246875800000066`,
        method:'get',
    })
}

// 查看单个演员
export function performerdetail (id){
    return request({
        url:`/sys/sysperformer/${id}`,
        method:'get',
    })
}


