import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/recruit',
    name: 'recruit',
    component: () => import(/* webpackChunkName: "about" */ '../views/Recruit.vue')
  },
  {
    path: '/schedule',
    name: 'schedule',
    component: () => import(/* webpackChunkName: "about" */ '../views/Schedule.vue')
  },
  {
    path: '/waittime',
    name: 'waittime',
    component: () => import(/* webpackChunkName: "about" */ '../views/Waittime.vue')
  },
  {
    path: '/access',
    name: 'access',
    component: () => import(/* webpackChunkName: "about" */ '../views/Access.vue')
  },
  {
    path: '/therapist',
    name: 'therapist',
    component: () => import(/* webpackChunkName: "about" */ '../views/Therapist.vue')
  } ,
  {
    path: '/detail/:id',
    name: 'detail',
    component: () => import(/* webpackChunkName: "about" */ '../views/Listdetail.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
  },
  {
    path: '/line',
    name: 'line',
    component: () => import(/* webpackChunkName: "about" */ '../components/Line.vue')
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
