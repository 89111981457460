<template>
  <div class="footer-block">
    <div class="footers">
      <div class="container">
        <ul class="block" >
          <li  @click="gomenu(item)" v-for="item in routemenu" :key="item.id">{{item.title}}</li>      
        </ul>
      </div>
    </div>
    <div class="footers-block">
      <div class="footer">
        <div class="titlepic">
          <img :src="dibulogo[0]" alt="" />
        </div>
        <div class="images-block" style="margin: 50px 0">
          <div class="picwidth"  v-for="item in dibu" :key="item.id">
            <img :src="item.title" alt=""  @click="goroute(item.route)"/>
          </div>
        </div>
      </div>
    </div>
    <div class="line">
      <div class="aicancan">Copyright (C)愛燦燦. All Rights Reserved.</div>
    </div>
  </div>
</template>
<script>
import { getbanner,getmenuList } from "@/api/home";
import { getmenu } from '@/api/menu';

export default {
  name: "Footer",
  data() {
    return {
      routerPath: "",
      dibu:[],
      dibulogo:[],
      routemenu:[],

    };
  },
  mounted() {
    this.routerPath = this.$route.path;
    this.bannerimgs();
    this.getmenu() 
    this.gomenus();

  },
  methods: {
    bannerimgs() {
      let dibulogo = [];
      getbanner().then(({ data: res }) => {
        dibulogo = res.filter((item) => item.status == 2);
        dibulogo.forEach((item) => {
          this.dibulogo.push(item.url);
        });
      });
    },

    getmenu() {
      let dibuimg = [];
      getmenuList().then(({ data: res }) => {
        dibuimg = res.filter((item) => item.status == 3);
        dibuimg.forEach((item) => {
          this.dibu.push(item);
        });
      });
    },
    gomenu(item) {
      let route = this.$route.path;
      let str = item.route.indexOf('/access');
      if(route=='/access'&&str!=-1){
        let obj = this.getUrlKey(item.route);
        if(obj.ID=='ASS'){
          document.getElementById("showD").scrollIntoView();
          this.navshow = false;
        }else{
          return
        }
      }else if(item.route==route){
        return
      } else if (item.isInternalLink == 1) {
        window.open(item.route)
      } else {
        this.$router.push(item.route);
      }   
    },
    getUrlKey(str){
      let obj = {};
      if(str && str.split('?')[1]){
        let queryArray = str.split('?')[1].split('&');
        queryArray.map((query) =>{
          let temp = query.split('=');
          if(temp.length > 1){
            obj[temp[0]] = temp[1];
          }
        })
      }
      return obj
    },
    gomenus(){
      getmenu().then(({data:res})=>{
        this.routemenu = res.filter(item=>item.status==0)

      })
    },
    goroute(item){
        window.open(item);
    }
  },
};
</script>

<style lang="less" scoped>
// 移动端
@media (max-width: 768px) {
  .footers {
    width: 100%;
    margin-top: 80px;
    // position: fixed;
    // bottom: 0;
    .container {
      // width: 50%;
      // margin: 0 auto;
      color: #969696;
      .block {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        cursor: pointer;
        li {
          margin-bottom: 0.8em;
          line-height: 1.65em;
          font-size: 1rem;
          width: 33%;
        }
      }
      .footers ul {
        margin: 12px 0 10px;
      }
      .footers li {
        text-align: left;
      }
      .footers-block {
        width: 100%;
        .footer {
          width: 80%;
          .titlepic {
            width: 15%;
            float: left;
            img {
              width: 100%;
              object-fit: cover;
              max-width: 100%;
              max-height: 100%;
            }
          }
          .images-block {
            display: flex;
            justify-content: end;
          }
        }
      }
    }
  }
  .footers-block {
    width: 100%;
    .footer {
      width: 94%;
      margin: 0 auto;
      margin-top: 30px;
      .images-block {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 0 auto;
        margin-top: 20px;

        .picwidth {
          margin: 0 2.5% 25px;
          width: 45%;
          box-sizing: border-box;

          img {
            height: auto;
            object-fit: cover;
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }
  }
  .line {
    // border-top: 1px solid red;
    border-top: #3e3e3e solid 1px;
    padding-top: 1em;
    padding-bottom: 1em;
    .aicancan {
      color: #646464;
      font-size: 12px;
      margin: 0 15px 5px;
    }
  }
}
// pc端
@media (min-width: 768px) {
  .footers {
    width: 100%;
    margin-top: 100px;
    .container {
      width: 60%;
      margin: 0 auto;
      color: #969696;
      .block {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        cursor: pointer;
        li {
          margin-bottom: 0.8em;
          line-height: 1.65em;
          font-size: 14px;
        }
      }
    }
  }
  .footers-block {
    width: 100%;

    .footer {
      width: 70%;
      margin: 0 auto;
      .titlepic {
        width: 8%;
        img {
          width: 100%;
          object-fit: cover;
          max-width: 100%;
          max-height: 100%;
        }
      }
      .images-block {
        display: flex;
        margin: 0 auto;
        justify-content: space-around;
        .picwidth {
          width: 200px;
          margin-left: 10px;
          box-sizing: border-box;
          img {
            height: auto;
            object-fit: cover;
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }
  }
  .line {
    // border-top: 1px solid red;
    border-top: #3e3e3e solid 1px;
    padding-top: 1em;
    padding-bottom: 1em;
    .aicancan {
      color: #646464;
      font-size: 12px;
      margin: 0 15px 5px;
    }
  }
}
</style>