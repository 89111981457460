<template>
  <div class="gomenus_box" >
    <div
      :class="`gomenus ${isShow ? 'gomenus' : ''}`"
     
    >
      <ul
        class="list"
        :class="{ 'select-router': routerPath == item.route }"
        @click="gomenu(item)"
        v-for="item in routemenu"
       :key="item.id"
      >
        <li class="top" v-if="item.title">{{ item.title }}</li>
        <li class="jap" style="padding-right: 2rem" v-if="item.subtitle">
          {{ item.subtitle }}
        </li>
      </ul>
    </div>

    <!-- <ul class="list" @click="go('CONCEPT')">
            <li class="top">CONCEPT</li>
            <li>コンセプト</li>
          </ul> -->
    <!-- <ul
      class="list"
      :class="{ 'select-router': routerPath == '/access' }"
      @click="go('MENU')"
    >
      <li class="top">MENU</li>
      <li style="padding-right: 2rem;">料金/システム</li>
    </ul>
    <ul
      class="list"
      :class="{ 'select-router': routerPath == '/waittime' }"
      @click="go('WAITTIME')"
    >
      <li class="top">WAITTIME</li>
      <li style="padding-right: 2rem;">待機情報</li>
    </ul>
    <ul
      class="list"
      :class="{ 'select-router': routerPath == '/therapist' }"
      @click="go('THERAPIST')"
    >
      <li class="top">THERAPIST</li>
      <li style="padding-right: 2rem;">セラピスト</li>
    </ul>
    <ul
      class="list"
      :class="{ 'select-router': routerPath == '/schedule' }"
      @click="go('SCHEDULE')"
    >
      <li class="top">SCHEDULE</li>
      <li style="padding-right: 2rem;">スケジュール</li>
    </ul>
    <ul
      class="list"
      :class="{ 'select-router': routerPath == '/access' }"
      @click="go('ACCESS')"
    >
      <li class="top">ACCESS</li>
      <li style="padding-right: 2rem;">アクセス</li>
    </ul>
    <ul
      class="list"
      :class="{ 'select-router': routerPath == '/recruit' }"
      @click="go('RECRUIT')"
    >
      <li class="top">RECRUIT</li>
      <li style="padding-right: 2rem;">リクルート</li>
    </ul> -->
  </div>
</template>
<script>
import { getmenu } from "@/api/menu";

export default {
  name: "HeaderList",
  data() {
    return {
      isShow: 0, //默认不显示导航   1：显示
      navshow: false,
      routerPath: "",
      screenWidth: "",
      screen: false,
      routemenu: [],
    };
  },
  mounted() {
    this.gomenus();

    this.routerPath = this.$route.path;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth >= 768) {
      this.screen = true;
    } else {
      this.screen = false;
    }
  },
  methods: {
    // go(item) {
    //   console.log(item,"底部");
    //   let routerPath = this.routerPath;
    //   if (item == "TOP") {
    //     this.$router.push("/");
    //   } else if (item == "MENU" && routerPath != "/access") {
    //   console.log(this.$route,"底部");

    //     this.$router.push({path:"/access",query: {ID:'MEE'}});
    //     // this.$router.replace('./access')
    //   } else if (item == "WAITTIME" && routerPath != "/waittime") {
    //     this.$router.push("/waittime");
    //   } else if (item == "THERAPIST" && routerPath != "/therapist") {
    //     this.$router.push("/therapist");
    //   } else if (item == "SCHEDULE" && routerPath != "/schedule") {
    //     this.$router.push("/schedule");
    //   } else if (item == "ACCESS" && routerPath != "/access") {
    //     this.$router.push({path:"/access",query: {ID:'ASS'}});
    //   } else if (item == "RECRUIT" && routerPath != "/recruit") {
    //     this.$router.push("/recruit");
    //   }
    // },
    gohome() {
      if (this.$route.path == "/") {
        return;
      } else {
        this.$router.push("/");
      }
    },
    menuclick(item) {
      if ((item = "contact")) {
        this.$router.push("/contact");
      }
    },
    navbar() {
      this.navshow = !this.navshow;
    },
    gomenu(item) {
      let route = this.$route.path;
      let str = item.route.indexOf("/access");
      if (route == "/access" && str != -1) {
        let obj = this.getUrlKey(item.route);
        if (obj.ID == "ASS") {
          document.getElementById("showD").scrollIntoView();
          this.navshow = false;
        } else {
          return;
        }
      } else if (item.route == route) {
        return;
      } else if (item.isInternalLink == 1) {
        window.open(item.route)
      } else {
        this.$router.push(item.route);
      }
    },
    getUrlKey(str) {
      let obj = {};
      if (str && str.split("?")[1]) {
        let queryArray = str.split("?")[1].split("&");
        queryArray.map((query) => {
          let temp = query.split("=");
          if (temp.length > 1) {
            obj[temp[0]] = temp[1];
          }
        });
      }
      return obj;
    },
    gomenus() {
      getmenu().then(({ data: res }) => {
        this.routemenu = res.filter((item) => item.status == 0);

      });
    },
    // gomenu(item) {
    //   if ((item = "TOP")) {
    //     this.$router.push("/");
    //   }
    // },
  },
};
</script>
<style lang="less" scoped>
// pc端
@media (min-width: 768px) {
  .gomenus {
		width: 100%;
    display: flex;
		align-items: center;
		justify-content: center;
    height: 90px;
    min-height: 4em;
    position: absolute;
    top: 110px;
    z-index: 55;
    font-family: Avenir, Helvetica, Arial, sans-serif;
		.list {
			box-sizing: border-box;
		  list-style: none;
		  margin: 0;
			color: #fff;
		  line-height: 1.4;
		  font-size: 12px;
		  cursor: pointer;
			position: relative;
			padding-bottom: 1rem;
		  li {
		    text-align: center;
		    font-size: 1em;
		    padding-right: 2em;
		    white-space: nowrap;
		    overflow: hidden;
		  }
		
		  .top {
		    padding-bottom: 1em;
		    font-weight: 200;
		    font-size: 1.4em;
		    white-space: nowrap;
		  }
		}
		.list:hover{
			color: #ffdb8d;
		}
		.list::after{
			content: '   ';
			display: inline-block;
			width: 0%;
			background:#ffdb8d;
			height: 1px;
			position: absolute;
			bottom: 0;
			left: 0;
			transition: width 0.36s;
		}
		
		.list:hover::after{
			content: '   ';
			display: inline-block;
			width: 100%;
			background:#ffdb8d;
			height: 1px;
			position: absolute;
			bottom: 0;
			left: 0;
		}
		.select-router::after {
			content: '   ';
			display: inline-block;
			width: 100%;
			background:#ffdb8d;
			height: 1px;
			position: absolute;
			bottom: 0;
			left: 0;
    }
		
    

		
  }
}

@media (max-width: 768px) {
  .gomenus {
    display: none;
  }
}
</style>

