<template>
	<div style="background: black">
		<div class="home">
			<!-- <img alt="Vue logo" src="../assets/logo.png"> -->
			<HelloWorld />
			<HeaderList />
			<div class="backimg"></div>
			<div class="textblock_box">
				<div class="textblock">Osaka Men's Esthetic</div>
			</div>

			<div class="texttitle">
				<div class="title-img"><img :src="aicancan[0]" alt="" /></div>
				<div class="text">
					<div>四十路・五十路・六十路</div>
					<div>専門店</div>
				</div>
			</div>
		</div>
		<!-- 轮播图 -->
		<div class="block-carousel">
			<div>
				<el-carousel class="bannerimg" indicator-position="none" :type="isShouji">
					<el-carousel-item v-for="item in img1" :key="item">
						<img :src="item" alt="" style="width: 100%;" />
					</el-carousel-item>
				</el-carousel>
			</div>

			<!-- INFORMATION -->
			<div class="block-bottom">
				<div v-for="item in INFORMATION" :key="item.id">
					<span class="eng" v-if="item.title">{{ item.title }}</span>
					<span class="chi" v-if="item.subtitle">{{ item.subtitle }}</span>
					<span class="chi" v-if="item.content">{{ item.content }}</span>
				</div>
				<div class="bottom-img">
					<img src="../assets/midashi_right_bottom.png" alt="" />
				</div>
			</div>
		</div>
		<!-- 紫块 -->
		<div class="popular">
			<div class="flex-body">
				<div><img src="../assets/side_spin.png" alt="" /></div>
				<div class="text">TODAY'S SCHEDULE</div>
				<div class="side_spin">
					<img src="../assets/side_spin.png" alt="" />
				</div>
			</div>
			<div class="day">本日の出勤</div>
			<div v-if="dingxiuri" style="color: white; margin-top: 5%">定休日</div>
			<div class="container">
				<div class="today-list">
					<div class="swiper-wrapper">
						<div class="slider" v-for="item in todaylists" :key="item.id" @click="godetail(item.performerId)">

							<div class="chuqin">
								<span>本日出勤時間：{{ item.timeParagraph }}</span>
							</div>
							<div class="tupian">
								<img :src="item.img ? item.imgs[0] : src" alt="" />
							</div>
							<div class="slider_cast_name">
								<span v-if="item.realName">{{ item.realName }}</span>
								<span v-if="item.age">({{ item.age }})</span>
								<span v-if="item.height">T:{{ item.height }}</span>
							</div>
							<div class="room_taiki">
								<div v-if="item.webName" class="store">{{ item.webName }}</div>
								<!-- <div v-if="item.yuyueTimedd" class="shijian">
                  最短{{ item.yuyueTimedd }}〜
                </div> -->
								<!-- <div class="shijian" v-if="item.standbyTime == 0">待機中</div>
                <div class="shijian" v-if="item.standbyTime == 1">受付终了</div> -->
								<div class="shijian">{{getstatus(item)}}</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- pick up -->
		<div class="pickup">
			<div class="pickup-title" v-for="item in pickuptitle" :key="item.id">
				<img src="../assets/pic.png" alt="" />
				<div class="text" v-if="item.title">{{item.title}}</div>
				<div class="ri" v-if="item.subtitle">{{item.subtitle}}</div>
			</div>
			<div class="pickup-carousel">
				<el-carousel :interval="5000" arrow="always" :height="screen ? '440px' : '400px'" indicator-position="none">
					<el-carousel-item v-for="item in pickup" :key="item.id">
						<div class="swiper-slide">
							<div class="one-cast">
								<a href="" class="pick_link">
									<div class="figure">
										<img src="../assets/0B12B403-0B96-490D-A1F1-A146B538C4BF.jpeg" alt="" />
									</div>
									<figcaption class="profile">
										<span class="new_cast">{{ item.isxin }}</span>
										<span class="cast-name">{{ item.name }}</span>
										<span class="cast-age">({{ item.age }}歳)</span>
										<span class="cast-size">T:{{ item.height }}</span>
										<div class="krc_calendar">
											<p>
												{{ item.pickup }}
											</p>
										</div>
									</figcaption>
								</a>
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
		<!-- 新人介绍 -->
		<div class="introduce" v-for="item in NEWFACE" :key="item.id">
			<div class="flex-body">
				<div class="jian"><img src="../assets/side_spin.png" alt="" /></div>
				<div class="text" v-if="item.title">{{ item.title }}</div>
				<div class="jian"><img src="../assets/side_spin.png" alt="" /></div>
			</div>
			<div class="ri" v-if="item.subtitle">{{ item.subtitle }}</div>
			<div class="carousel">
				<el-carousel type="card" :autoplay="true" :interval="5000" arrow="always" :height="screen ? '500px' : '300px'" class="pickwidth" indicator-position="none">
					<el-carousel-item v-for="item in xinren" :key="item.id">
						<div class="swiper" @click="godetail(item.id)">
							<img :src="item.img ? item.imgs[0] : src" alt="" />
							<figcaption class="top_slider_cast_name">
								<span class="cast-name">{{
                  item.realName ? item.realName : ""
                }}</span>
								<span class="cast-age">({{ item.age ? item.age : "" }})</span>
								<br />
								<span class="cast-size">T:{{ item.height ? item.height : "" }}</span>
							</figcaption>
							<div class="new_cast">{{ egress(item.newPeople) }}</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
		<!-- CONCEPT -->
		<div class="block-concept">
			<div class="concept">
				<div class="block" v-for="item in concept" :key="item.id">
					<div class="concept-title">
						<span class="eng">{{ item.title }}</span>
						<span class="ri">{{ item.subtitle }}</span>
					</div>
					<div class="word-text">
						<div v-if="item.content" v-html="item.content"></div>
					</div>
				</div>
			</div>
		</div>

		<!-- Twitter -->
		<div class="reservation-block">
			<div class="reservation">
				<div class="twitter">
					<div class="text" style="height: 28px;">Twitter</div>
					<!-- <div style="text-align: start; margin-top: 30px; color: #337ab7">
            Tweets by kansya_men_esu
          </div> -->
					<div class="twitter_box" style="">
						<div class="box_top" ref="box_top">
							<a class="box_top_a1" href="https://twitter.com/ai_san_san0521?ref_src=twsrc%5Etfw%7Ctwcamp%5Eembeddedtimeline%7Ctwterm%5Escreen-name%3Aai_san_san0521%7Ctwcon%5Es2">@ai_san_san0521さんのツイート</a>
							<a class="box_top_a2" href="https://twitter.com/intent/follow?screen_name=ai_san_san0521&ref_src=twsrc%5Etfw%7Ctwcamp%5Eembeddedtimeline%7Ctwterm%5Escreen-name%3Aai_san_san0521%7Ctwcon%5Es2">フォローする</a>
						</div>

						<div class="box_bottom" :style="`height:calc(100% - ${twitter_top_h}px);`">
							<div>
								<img src="../assets/twitter.png" alt="" />
							</div>
							<div class="box_bottom_text1" style="">
								通知はまだ届いていません
							</div>
							<div class="box_bottom_text2" style="">
								ツイートがあるとここに表示されます。
							</div>
							<div @click="teitterFn()" class="box_bottom_btn" style="">
								Twitterで表示
							</div>
						</div>

					</div>
				</div>
				<div class="yuyue-block">
					<div class="title" v-for="item in Reservation" :key="item.id">
						<span class="eng" v-if="item.title">{{ item.title }}</span>
						<span class="ri" v-if="item.subtitle">{{ item.subtitle }}</span>
					</div>
					<div v-for="item in titledetail" :key="item.id">
						<div class="block" @click="yuyues(item)">
							<div><img src="../assets/left_tel.png" alt="" /></div>
							<div>
								<div class="tel">{{item.title}}</div>
								<div class="phone">{{item.subtitle}}</div>
							</div>
							<div><img src="../assets/right_arrow.png" alt="" /></div>
						</div>
						<!-- <div class="block" @click="yuyues('LINE')">
              <div><img src="../assets/left_tel.png" alt="" /></div>
              <div>
                <div class="tel">LINE@</div>
                <div class="phone">LINE@で予約する</div>
              </div>
              <div><img src="../assets/right_arrow.png" alt="" /></div>
            </div>
            <div class="block" @click="yuyues('MAIL')">
              <div><img src="../assets/left_tel.png" alt="" /></div>
              <div>
                <div class="tel">MAIL</div>
                <div class="phone">メールで予約する</div>
              </div>
              <div><img src="../assets/right_arrow.png" alt="" /></div>
            </div> -->
					</div>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
	import {
		todaylist,
		dingxiuri,
		weektodaylist
	} from "../api/schedule";
	import HelloWorld from "@/components/HelloWorld.vue";
	import Footer from "../components/footer.vue";
	import HeaderList from "../components/headerlist.vue";
	import moment from "moment";
	import {
		performerpage
	} from "../api/sysperformer";
	import src from "../assets/junbi.webp";
	// import { getbanner } from "@/api/home";
	import {
		richtext
	} from "@/api/access";
	import {
		getmenuList,
		gettime,
		getbanner
	} from "@/api/home";
	import {
		timeParagraph
	} from "../api/save";


	export default {
		name: "HomeView",
		components: {
			HelloWorld,
			Footer,
			HeaderList,
		},
		data() {
			return {
				isShouji: 'card',
				isShow: 0, //默认不显示导航 1：显示
				carousel: true,
				todaylists: [],
				aicancan: [],
				INFORMATION: [],
				routerPath: "",
				img1: [],
				screenWidth: "",
				screen: false,
				xinren: [],
				pickuplist: [],
				dingxiuri: false,
				src: "",
				concept: [],
				pickup: [{
						id: 1,
						isxin: "新人",
						name: "葉月セラピスト",
						age: 52,
						height: 17,
						pickup: "誰しもが頷くクオリティのルックスをお持ちの新人セラピストさんです。業界未経験ですが、真面目な性格な方で、これからの活躍には目を離せません。予約が殺到する事が予測されますので、是非お早めのご予約をおすすめ致します。",
					},
					{
						id: 2,
						isxin: "新人",
						name: "葉月セラピスト",
						age: 52,
						height: 17,
						pickup: "誰しもが頷くクオリティのルックスをお持ちの新人セラピストさんです。業界未経験ですが、真面目な性格な方で、これからの活躍には目を離せません。予約が殺到する事が予測されますので、是非お早めのご予約をおすすめ致します。",
					},
				],
				SCHEDULE: [],
				pickuptitle: [],
				NEWFACE: [],
				Reservation: [],
				titledetail: [],
				yuyueend: "",
				// waitting:"待機中",
				prohibitedTime: [],
				min: "",
				twitter_top_h: 55, //twitter top 高度
			};
		},
		computed: {

		},
		created() {
			this._isMobile();
		},
		mounted() {
			document.title='トップページ - 愛燦燦';
			window.addEventListener('resize', this.handleResize); //监听屏幕变化

			this.src = src;
			let today = moment().format("YYYY-MM-DD");
			if (new Date().getHours() < 7) {
				today = moment().subtract(1, "days").format("YYYY-MM-DD");
			}
			let deptId = '1067246875800000066'
			const currentTime = moment().format('HH:mm:ss');
			console.log(currentTime, '时分秒');
			let sfm = `${today} ${currentTime}`
			this.todaydate(today, deptId, sfm);
			this.screenWidth = window.innerWidth;
			if (this.screenWidth >= 768) {
				this.screen = true;
			} else {
				this.screen = false;
			}
			getmenuList().then(res => {
				this.titledetail = res.data.filter((item) => item.status == 2);
			})
			this.getperformerpage();
			this.dingxiu();
			this.bannerimgs();
			this.getrichtext();
		},
		methods: {
			//twitter
			teitterFn() {

				window.open('https://twitter.com/ai_san_san0521?ref_src=twsrc%5Etfw%7Ctwcamp%5Eembeddedtimeline%7Ctwterm%5Escreen-name%3Aai_san_san0521%7Ctwcon%5Es1', '_blank')
			},
			//
			handleResize() {
				//屏幕变化 - twitter 高度
				console.log(this.$refs.box_top.offsetHeight, '999999999')
				this.twitter_top_h = this.$refs.box_top.offsetHeight;
			},
			//判断是否为手机端
			_isMobile() {
				let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
				if (flag) {
					//手机
					this.isShouji = '';
				} else {
					//pc
					this.isShouji = 'card';
				}
			},
			nalue() {
				gettime().then(res => {
					console.log(res.data);
					let todaList = this.todaylists;
					for (let i = 0; i < todaList.length; i++) {
						for (let j = 0; j < res.data.length; j++) {
							if (todaList[i].performerId == res.data[j].performerId) {
								if (todaList[i].yuyueTimedd) {
									continue;
								} else {
									let time = this.timeOnChange(res.data[j].timeParagraphOff.split('-')[1]) + 20;
									const now = new Date().getTime();
									let shifen = res.data[j].timeParagraphOff.split('-')[0]
									const year = moment().format("YYYY-MM-DD")
									let starttime = `${year} ${shifen}:00`
									let starttimeChuo = new Date(starttime).getTime();
									if (starttimeChuo < now) {
										todaList[i].yuyueTimedd = this.timeFilter(time);
									} else {
										this.waitting = '待機中'
									}
								}
							}
						}
					}
				})
			},
			timeFilter(val) {
				if (val) {
					//举例：将240分钟转为04:00这样的格式
					const duration = moment.duration(val, "minutes");
					const formattedTime = moment
						.utc(duration.asMilliseconds())
						.format("HH:mm");
					return formattedTime; // moment(formattedTime, 'HH:mm')
				} else {
					return "00:00"; // moment('00:00', 'HH:mm')
				}
			},
			timeOnChange(timeString) {
				// 举例：将04:00转为240分钟这样的格式
				const duration = moment.duration(timeString);
				const minutes = duration.asMinutes();
				return minutes;
			},
			goxinrendetail(id) {},
			// 定休日
			dingxiu() {
				let today = moment().format("YYYY-MM-DD");
				dingxiuri(today).then(({
					data: res
				}) => {
					if (res) {
						res.forEach((item) => {
							if (item == today) {
								this.dingxiuri = true;
							} else {
								this.dingxiuri = false;
							}
						});
					}
				});
			},
			egress(item) {
				return item ? "新人" : "";
			},
			// 新人演员
			getperformerpage() {
				performerpage().then(({
					data: res
				}) => {
					let resdata = res;
					resdata.forEach((item) => {
						if (item.img) {
							item["imgs"] = item.img.split(",");
						} else {
							item["imgs"] = [];
						}
					});
					this.xinren = resdata.filter((items) => items.newPeople == 1);
				});
			},
			// 查询已预约时段
			gettimeParagraph() {

			},
			// 本日出勤
			async todaydate(date, deptId, todaytime) {
				weektodaylist({
					date,
					deptId,
					todaytime
				}).then(res => {
					this.todaylists = res.data;
					console.log(res.data, 'this.todaylists');

					console.log(this.todaylists, '11111122222');
					this.todaylists.forEach((item) => {
						if (item.img) {
							item["imgs"] = item.img.split(",");
						} else {
							item["imgs"] = [];
						}
						// this.waittingtext(item)
						// console.log(item,'**********');
						// let prohibitedTime = [];
						// let yuyyueitem = item;
						// let imeParagraphs = yuyyueitem.timeParagraph.split("-");
						// let startTimesss = imeParagraphs[0];
						// let endTimesss = imeParagraphs[1];
						// let min = '';

						// let timOne = this.timeOnChange(imeParagraphs[0]);
						// let timTwo = this.timeOnChange(imeParagraphs[1]);

						// if (timOne > timTwo) {
						//   endTimesss = endTimesss.split(":");
						//   endTimesss[0] = endTimesss[0] * 1 + 24;
						//   endTimesss = endTimesss.join(":");
						// }

						// const params = {
						//   performerId: yuyyueitem.performerId,
						//   date: yuyyueitem.date,
						// };

						// timeParagraph(params).then(({ data: res }) => {
						//   let timeParagraphOff = [];
						//   if (res.length) {
						//     res.forEach((item) => {
						//       timeParagraphOff.push(item.timeParagraphOff);
						//     });
						//   }
						//   prohibitedTime = this.ShowoptionalTimeFn(
						//     startTimesss,
						//     endTimesss,
						//     yuyyueitem,
						//     timeParagraphOff
						//   );

						//   let now = moment().format('HH:mm').split(':');

						//   if(now[0]>0&&now[0]<4){
						//     now[0] = now[0]*1+24;
						//   }

						//   now = this.timeOnChange(now.join(':'));
						//   let waitList = prohibitedTime.filter(item=> now<=item[1]);//可预约时段（筛掉已过期的时间）
						//   // 无已预约时间段
						//   if(!waitList.length){
						//     min = '受付终了'
						//   //无已预约时间段  只有出勤时间
						//   }else if(waitList.length==1) {
						//     // 当前时间小于第一条预约时间的开始时间
						//     if(now<waitList[0][0]){
						//       // 显示第一条预约时间的开始时间
						//       min = `最短 ${this.timeFilter(waitList[0][0]- item.waitTime)} `;
						//       // 当前时间大于第一条预约时间的开始时间  并且  第一条预约时间的结束时间减去当前时间大于等于60分钟时长
						//     }else if(now>waitList[0][0]&&(waitList[0][1]-now)>=60){
						//       min = '待機中';
						//     }else{
						//       min = '受付终了'
						//     }
						//   }else{
						//     for(let i = 0;i<waitList.length;i++){
						//       // 当前时间小于第一条预约时间的开始时间
						//       if(now<waitList[i][0]){
						//       // 显示第一条预约时间的开始时间
						//         min = `最短 ${this.timeFilter(waitList[0][0]- item.waitTime)} `;
						//         //当前时间大于第一条可预约时间段的开始时间  并且 可预约时间段的结束时间减去当前时间 大于等于60分钟时长
						//       }else if(now>waitList[i][0]&&(waitList[0][1]-now)>=60){
						//         min = '待機中';
						//         //
						//       }else{
						//         min = '受付终了'
						//       }
						//     }
						//   }
						//   item.waitText = min;
						// });
					});

				});
			},
			getstatus(item) {
				console.log(item.standbyTime, 'status');
				if (item.standbyTime == 0) {
					return '待機中'
				} else if (item.standbyTime == 1) {
					return '受付终了'

				} else {
					return `最短${item.lastAvailableTime}`

				}
			},
			yuyues(item) {
				if (item.route == '/TEL') {
					// window.location.href = "tel://" + '050-8883-9626';
					console.log(item, 'item============');
					window.location.href = 'tel:050-8883-9626';
				} else if (item.route == "/LINE") {
					var yourWindow = window.open();
					yourWindow.opener = null;
					yourWindow.location = item.route;
					yourWindow.target = "_blank";
				} else {
					window.location.href = item.route;
					// this.$router.push(item);
				}
			},
			getImg(url) {
				let img = require(url);
				return img;
			},
			godetail(id) {
				this.$router.push({
					path: `/detail/${id}`,
				});
			},
			// 轮播图获取
			bannerimgs() {
				let banner = [];
				let dibuimg = [];
				let aicancan = [];
				getbanner().then(({
					data: res
				}) => {
					banner = res.filter((item) => item.status == 0);
					banner.forEach((item) => {
						let bannerimage = item.url.split(',')
						bannerimage.forEach(items => {
							this.img1.push(items);
						})
						// this.img1.push(item);
					});

					aicancan = res.filter((item) => item.status == 6);
					aicancan.forEach((item) => {
						let bannerimage = item.url.split(',')
						bannerimage.forEach(items => {
							this.aicancan.push(items);
						})
					});
				});
			},
			getrichtext() {
				richtext().then(({
					data: res
				}) => {
					this.concept = res.filter((item) => item.status == 4);
					this.INFORMATION = res.filter((item) => item.status == 5);
					this.SCHEDULE = res.filter((item) => item.status == 6);
					this.pickuptitle = res.filter((item) => item.status == 7);
					this.NEWFACE = res.filter((item) => item.status == 8);
					this.Reservation = res.filter((item) => item.status == 9);
				});
			},
			ShowoptionalTimeFn(starTime, endTime, yuyyueitem, list) {
				// this.iskeyuyue=true;//不可选择时间
				this.yuyueTimeplaceholder = "開始時間をお選びください"; //预约开始时间默认提示
				let selectableRange = []; //重置
				// console.log('计算预约时段数据：', starTime, endTime, intervalTime, list)
				//判断该演员今天有没有预约过；
				if (!list.length) {
					//当天没有预约过（第一次预约）
					starTime = this.timeOnChange(starTime);
					endTime = this.timeOnChange(endTime); //分钟已加
					// console.log('第一次预约',starTime,endTime)
					//判断结束日期在第二天的情况

					// console.log(endTime)
					selectableRange.push([starTime, endTime]);
					return selectableRange;
					// this.iskeyuyue=false;//可选择时间
					console.log("展示展示", selectableRange);
				} else {
					// 开始时间（分钟）
					starTime = this.timeOnChange(starTime);
					// 结束时间（分钟）超过当天加一天（已加）
					// console.log(endTime)
					endTime = this.timeOnChange(endTime);
					//一整天的分钟
					let oneDayTime = this.timeOnChange("24:00");
					//1.计算上班时间到第一次预约的时间；2.判断两个时间段之间；3.判断结束时间到最后一次预约结束时间
					//00:00时间先转换为分钟
					let listMin = []; //需要排序（时间先后）
					// 已预约数组转换为分钟
					list.forEach((item) => {
						let single = "";
						single = item.split("-");
						let str = this.timeOnChange(single[0]); //开始时间
						let end = this.timeOnChange(single[1]); //结束时间
						if (starTime > str) {
							str = str + oneDayTime;
						}
						if (starTime > end) {
							end = end + oneDayTime;
						}
						listMin.push({
							str: str, //开始时间
							end: end, //结束时间
						});
					});
					// 已预约数组排序
					listMin.sort(this.sortBy("str"));
					// console.log(listMin, 'listMin已排序')
					//判断得出可预约时段
					let timeStr = starTime; //首次对比时间（开始）
					listMin.forEach((item) => {
						//从开始时间判断 this.waitTime（等待时间）
						// console.log(item.str, timeStr, intervalTime)
						if (item.str - timeStr >= yuyyueitem.waitTime + 60) {
							let end = item.str - yuyyueitem.waitTime;
							selectableRange.push([timeStr, end]);
						}
						//第n次对比时间（开始）（这次的结束时间就是下次的开始时间）
						timeStr = item.end;
					});
					//补充最后空闲时间
					if (endTime - timeStr > 0) {
						let end = endTime;
						//补充最后空闲时间
						if (end - timeStr >= yuyyueitem.waitTime + 60) {
							selectableRange.push([timeStr + yuyyueitem.waitTime, end]);
						}
					}

					return selectableRange;
				}
			},
			sortBy(props) {
				return function(a, b) {
					return a[props] - b[props];
				};
			},
			timeOnChange(timeString) {
				// 举例：将04:00转为240分钟这样的格式
				const duration = moment.duration(timeString);
				const minutes = duration.asMinutes();

				// console.log(minutes); // 240
				return minutes;
			},
			//分钟转换00:00
			timeFilter(val) {
				if (val) {
					//举例：将240分钟转为04:00这样的格式
					const duration = moment.duration(val, "minutes");
					const formattedTime = moment
						.utc(duration.asMilliseconds())
						.format("HH:mm");
					return formattedTime; // moment(formattedTime, 'HH:mm')
				} else {
					return "00:00"; // moment('00:00', 'HH:mm')
				}
			},

		},
	};
</script>
<style lang="less" scoped>
	// 移动端
	@media (max-width: 768px) {

		// .bannerimg{
		//   height: 200px;
		//   // width: 50%;
		//   // margin: 0 auto;
		// }
		/deep/.el-carousel__mask {
			width: 0% !important;
		}

		.home {
			width: 100%;
			position: relative;

			.backimg {
				background-image: url("../assets/sp_mv1.webp");
				background-size: cover;
				background-position: center center;
				object-fit: cover;
				height: 450px;
				width: 100%;
			}

			.gomenu {
				display: flex;
				right: 25%;
				width: auto;
				height: 90px;
				min-height: 4em;
				position: absolute;
				top: 100px;
				z-index: 55;

				.list {
					list-style: none;
					border-bottom: 1px solid #ffdb8d;
					margin: 0;
					line-height: 1.4;
					font-size: 12px;
					cursor: pointer;

					li {
						text-align: center;
						font-size: 0.6em;
						color: #fff;
					}

					.top {
						padding-bottom: 3em;
						font-weight: 200;
						font-size: 1.4em;
						white-space: nowrap;
					}
				}
			}

			.textblock_box {
				width: 100%;
				position: absolute;
				top: 25%;
				display: flex;
				justify-content: center;
			}

			.textblock {
				width: 12rem;
				height: 3em;
				line-height: 3em;
				color: #fff;
				font-size: 0.4em;
				background: linear-gradient(90deg,
						rgba(172, 148, 94, 1) 0%,
						rgba(222, 188, 118, 1) 50%,
						rgba(201, 175, 116, 1) 100%);
				padding: 0.2em 4em;
				border-radius: 50px;
				letter-spacing: 0.1em;

			}

			.texttitle {
				position: absolute;
				top: 40%;

				.title-img {
					width: 50%;
					margin: 0 auto;

					img {
						width: 100%;
						object-fit: cover;
						max-width: 100%;
						max-height: 100%;
					}
				}

				.text {
					font-size: 1.7rem;
					color: white;
					font-family: ui-monospace;
					white-space: nowrap;
				}
			}
		}

		// 轮播图
		.block-carousel {
			width: 86%;
			margin: 0 auto;

			.carousel {
				width: 90%;
				height: 200px;
				margin: 0 auto;

				.pickwidth {
					width: 92%;
					margin: 0 auto;
				}

				.el-carousel__indicator--horizontal {
					padding: 0 !important;
				}
			}

			.block-bottom {
				display: flex;
				width: 85%;
				height: 4s0px;
				margin: 0 auto;
				border-bottom: 1px solid #c1ae84;
				margin-top: 100px;
				margin-bottom: 100px;
				color: #c1ae84;
				justify-content: space-between;

				.eng {
					font-size: 25px;
					font-family: fangsong;
					font-weight: 600;
					letter-spacing: 2px;
					white-space: nowrap;
				}

				.chi {
					font-weight: bold;
					margin-left: 50px;
					white-space: nowrap;
					font-size: 0.2rem;
				}

				.bottom-img {
					width: 50px;
					padding-right: 20px;
					object-fit: cover;
					max-width: 100%;
					max-height: 100%;
				}
			}
		}

		// 紫块
		.popular {
			width: 100%;
			background-image: url(../assets/schedule_back.png);
			background-repeat: no-repeat;
			background-size: cover;
			border-top: 2px solid #c1ae84;
			border-bottom: 2px solid #c1ae84;

			.flex-body {
				display: flex;
				width: 100%;
				height: 110px;
				margin: 0 auto;
				justify-content: space-evenly;
				align-items: end;
				overflow: hidden;

				.text {
					color: #c1ae84;
					text-align: center;
					font-size: 26px;
					font-family: math;
					letter-spacing: 1px;
					font-weight: 500;
					white-space: nowrap;
				}

				.side_spin {}
			}

			.day {
				color: #c1ae84;
				font-size: 14px;
				font-family: ui-monospace;
				margin-top: 30px;
			}

			.container {
				width: 100%;
				padding: 0 5px;
				box-sizing: border-box;

				.today-list {
					margin: 30px auto;
					box-sizing: border-box;

					.swiper-wrapper {
						max-width: 1100px;
						transform: translateZ(0);
						position: relative;
						width: 100%;
						z-index: 1;
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						transition-property: transform;
						box-sizing: content-box;

						.slider {
							width: 49%;
							margin-bottom: 0.8em;
							flex-shrink: 0;
							height: 100%;
							position: relative;
							box-sizing: border-box;
							color: #fff;
							background: black;
							padding: 0 5px;
							margin-left: 3px;

							.chuqin {
								height: 30px;
								line-height: 30px;
								font-size: 0.2em;
								padding: 0;
								display: block;
								color: #969696;
								font-weight: normal;
							}

							.tupian {
								width: 100%;
								height: 210px;
								object-fit: cover;

								img {
									width: 100%;
									height: 100%;
									object-fit: cover;

									max-width: 100%;
									max-height: 100%;
								}
							}

							.slider_cast_name {
								height: 30px;
								font-size: 0.9em;
								text-align: left;
								overflow: hidden;
								line-height: 30px;
							}

							.room_taiki {
								display: flex;
								justify-content: space-between;
								align-items: center;
								height: 50px;
								font-size: 0.7em;
								text-align: left;

								.store {
									overflow: hidden;
								}

								.shijian {
									background: #580742;
									padding: 0 1em;
									line-height: 35px;
									height: 35px;
									box-sizing: border-box;
									overflow: hidden;
									white-space: nowrap;
								}
							}
						}
					}
				}
			}
		}

		.pickup {
			width: 100%;

			.pickup-title {
				width: 80%;
				color: #c1ae84;
				margin: 0 auto;
				margin-top: 80px;
				margin-bottom: 50px;

				img {
					width: 100%;
					object-fit: cover;
					max-width: 100%;
					max-height: 100%;
				}

				.text {
					font-size: 30px;
					font-family: serif;
					font-weight: bold;
					margin: 22px;
				}

				.ri {
					font-size: 12px;
					font-weight: bold;
				}
			}

			.pickup-carousel {
				width: 88%;
				margin: 0 auto;

				.swiper-slide {

					// border: solid 1.5px #c1ae84;
					// padding-bottom: 10px;
					// width: 340px;
					// margin-right: 10px;
					.one-cast {
						padding: 20px;
						// background: #000;
						position: relative;
						height: 400px;
						.pick_link{
							height: 100%;
							display: flex;
							align-items: center;
							// flex-direction: column;
						}
						a {
							position: static;
							// display: block;
							color: #fff;
							box-shadow: none;
							text-decoration: none;

							.figure {
								margin: 0;
								float: left;
								margin-right: 1em !important;
								margin-bottom: 1em !important;
								width: 50%;
								object-fit: cover;
								max-width: 100%;
								max-height: 100%;

								img {
									height: auto;
									max-width: 100%;
									border: none;
									border-radius: 0;
									box-shadow: none;
									object-fit: cover;
									max-width: 100%;
									max-height: 100%;
								}
							}

							.profile {
								display: block;
								width: 50%;

								.new_cast {
									margin-right: 0.5em;
									margin-left: 0;
									line-height: 2;
									background: #580742;
									padding: 0.2em 1em 0.2em 1em;
									border-radius: 0;
									margin-top: 5px;
									margin-bottom: 10px;
									display: inline-table;
								}

								.cast-name {
									display: block;
									margin-right: -10px;
								}

								.cast-age {
									margin-left: 1em;
									font-size: 0.9em;
								}

								.cast-size {
									padding-top: 1em;
									border-top: 1px solid #707070;
								}

								.krc_calendar p {
									color: #969696;
									position: relative;
									font-size: 0.6em;
									line-height: 1.6;
									box-sizing: border-box;
								}
							}
						}
					}

					a.pick_link:before {
						content: "";
						display: inline-block;
						width: 50px;
						height: 50px;
						background-image: url("../assets/pick_bottom_left.png");
						background-size: contain;
						vertical-align: middle;
						position: absolute;
						bottom: 9%;
						left: 0px;
					}

					a.pick_link:after {
						content: "";
						display: inline-block;
						width: 50px;
						height: 50px;
						background-image: url("../assets/pick_bottom_right.png");
						background-size: contain;
						vertical-align: middle;
						position: absolute;
						bottom: 9%;
						right: 0px;
					}
				}

				.one-cast:before {
					top: 0;
					content: "";
					display: inline-block;
					width: 50px;
					height: 50px;
					background-image: url("../assets/pick_top_left.png");
					background-size: contain;
					vertical-align: middle;
					position: absolute;
					left: 0;
				}

				.one-cast::after {
					top: 0;
					content: "";
					display: inline-block;
					width: 50px;
					height: 50px;
					background-image: url("../assets/pick_top_right.png");
					background-size: contain;
					vertical-align: middle;
					position: absolute;
					right: 0;
				}
			}

			.swiper-slide:after {
				content: "";
				border: solid 1px #c1ae84;
				position: absolute;
				top: 2px;
				left: 2px;
				width: calc(100% - 4px);
				height: calc(100% - 4px);
			}

			// .el-carousel__item h3 {
			//   color: #475669;
			//   font-size: 18px;
			//   opacity: 0.75;
			//   line-height: 300px;
			//   margin: 0;
			// }
			// .el-carousel__item:nth-child(2n) {
			//   background-color: #99a9bf;
			// }

			// .el-carousel__item:nth-child(2n + 1) {
			//   background-color: #d3dce6;
			// }
		}

		// 新人介绍 移动
		.introduce {
			color: #c1ae84;

			.flex-body {
				display: flex;
				height: 140px;
				justify-content: space-between;
				align-items: center;

				.jian {
					width: 50px;
					object-fit: cover;
					max-width: 100%;
					max-height: 100%;

					img {
						width: 100%;
						object-fit: cover;
						max-width: 100%;
						max-height: 100%;
					}
				}

				.text {
					text-align: center;
					font-size: 26px;
					font-family: math;
					letter-spacing: 1px;
					font-weight: 500;
					white-space: nowrap;
				}
			}

			.ri {
				font-size: 12px;
				font-weight: bold;
				margin-bottom: 20px;
			}

			.swiper {
				width: 150px;
				margin: 0 auto;
				object-fit: cover;
				max-width: 100%;
				max-height: 100%;

				img {
					width: 100%;
					object-fit: cover;
					max-width: 100%;
					max-height: 100%;
				}

				.top_slider_cast_name {
					width: 98%;
					margin: 0 auto;
					box-sizing: border-box;
					text-align: center;
					font-size: 0.5em;

					.cast-name {
						margin-left: 0.1em;
					}

					.cast-age {
						margin-left: 1em;
					}

					.cast-size {
						margin-left: 0.2em;
					}
				}

				.new_cast {
					margin-right: 0.5em;
					margin-left: 0.1em;
					line-height: 1.2em;
					background: #580742;
					padding: 0.2em 0.5em;
					border-radius: 0;
					margin-top: 5px;
					margin-bottom: 10px;
					display: inline-block;
					font-size: 1em;
				}
			}
		}

		// concept
		.block-concept {
			width: 100%;
			box-sizing: border-box;

			.concept {
				width: 80%;
				margin: 0 auto;

				.block {
					width: 100%;

					.concept-title {
						text-align: left;

						.eng {
							font-size: 1.5rem;
							color: #c1ae84;
							font-family: fangsong;
							font-weight: bold;
							letter-spacing: 1px;
						}

						.ri {
							color: #c1ae84;
							font-size: 0.7rem;
							margin-left: 40px;
						}
					}

					.title {
						color: #fff !important;
						line-height: 3.5;
						border-bottom: 1px solid #b7a9a9;
						text-align: left;
						font-size: 1.5rem;
						white-space: nowrap;
					}

					.word-text {
						text-align: left;
						color: #fff;
						font-size: 0.8rem;
						line-height: 2;
					}

					.xiaozi {
						text-align: left;
						color: #fff;
						font-size: 0.7rem;
						line-height: 3;
						margin-top: 40px;
					}
				}
			}
		}

		// 预约
		.reservation-block {
			margin-top: 200px;
			width: 100%;

			.reservation {
				width: 100%;
				margin: 0 auto;
				color: #c1ae84;
				justify-content: space-between;

				.twitter {
					width: 80%;
					margin: 0 auto;

					.text {
						font-size: 1.5rem;
						font-family: fangsong;
						font-weight: 600;
						text-align: left;
					}
					.twitter_box {
						box-sizing: border-box;
						background-color: #fff;
						height: calc(100% - 48px);
						margin-top: 20px;
						    border-radius: 15px;
						.box_top {
							box-sizing: border-box;
							// height: 55px;
							line-height: 23px;
							padding: 12px;
							display: flex;
							justify-content: space-between;
							flex-direction: column;
							border-bottom: 1px solid rgb(207, 216, 222);
					
							a {
								text-decoration: none;
								color: inherit;
								font-weight: bold;
							}
					
							.box_top_a1 {
								font-size: 20px;
								color: #000;
					
							}
					
							.box_top_a2 {
								width: 120px;
								box-sizing: border-box;
								border-radius: 22.5px;
								font-size: 14px;
								background-color: #000;
								color: #fff;
								padding: 8px 16px;
							}
						}
					
						.box_bottom {
							overflow: auto;
							text-align: left;
							box-sizing: border-box;
							padding: 32px 34px;
					
							.box_bottom_text1 {
								margin-top: 30px;
								font-size: 31px;
								font-weight: bold;
								color: #000;
							}
					
							.box_bottom_text2 {
								margin-top: 8px;
								font-size: 15px;
								color: #536471;
								margin-bottom: 28px;
							}
					
							.box_bottom_btn {
								color: #fff;
								font-weight: bold;
								height: 50px;
								padding: 0 32px;
								box-sizing: border-box;
								border-radius: 25px;
								display: inline-block;
								line-height: 50px;
								background: #1d9bf0;
							}
						}
					}
				}

				.yuyue-block {
					width: 80%;
					margin: 0 auto;

					.title {
						text-align: left;
						margin-bottom: 30px;
						margin-top: 30px;

						.eng {
							font-size: 1.5rem;
							font-family: fangsong;
							font-weight: 600;
						}

						.ri {
							font-size: 0.8rem;
							font-family: fangsong;
							font-weight: 600;
							margin-left: 50px;
						}
					}

					.block {
						box-sizing: border-box;
						display: flex;
						line-height: 1.2;
						background-color: #580742;
						font-size: 1.8em;
						padding: 24px 24px;
						-webkit-border-radius: 3px;
						border-radius: 0;
						fill: #fff;
						text-align: center;
						-webkit-transition: all 0.3s;
						-o-transition: all 0.3s;
						transition: all 0.3s;
						border: 1px solid #c1ae84;
						justify-content: space-between;
						align-items: center;
						margin-top: 20px;

						.tel {
							font-size: 1.7rem;
							font-family: fangsong;
							font-weight: bold;
							letter-spacing: 2.1px;
						}

						.phone {
							font-size: 0.8rem;
							margin-top: 20px;
						}
					}
				}
			}
		}
	}

	body {
		background-color: black;
		padding: 0;
		margin: 0;
	}

	// pc端

	@media (min-width: 768px) {
		.bannerimg {
			width: 100%;
			margin: 0 auto;
		}

		/deep/.el-carousel__container {
			height: 450px !important;
		}

		/deep/.el-carousel__mask {
			width: 0% !important;
		}

		.navAYD {
			display: none;
		}

		.home {
			width: 100%;
			position: relative;

			.backimg {
				background-image: url("../assets/MV_PC.webp");
				background-size: cover;
				background-position: center center;
				object-fit: cover;
				height: 750px;
				width: 100%;
			}

			.textblock_box {
				width: 100%;
				position: absolute;
				top: 35%;
				display: flex;
				justify-content: center;
			}

			.textblock {
				left: calc(50% - 184px);
				color: #fff;
				font-size: 0.8em;
				background: linear-gradient(90deg,
						rgba(172, 148, 94, 1) 0%,
						rgba(222, 188, 118, 1) 50%,
						rgba(201, 175, 116, 1) 100%);
				padding: 0.2em 4em;
				border-radius: 50px;
				// letter-spacing: 0.1em;
				width: 368px;
				height: 30px;
				line-height: 30px;
				// margin: auto;
				white-space: nowrap;
			}

			.texttitle {
				position: absolute;
				top: 45%;
				right: calc(50% - 25rem);
				width: 50rem;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;

				.title-img {
					// width: 40%;
					object-fit: cover;

					// max-width: 100%;
					// max-height: 100%;
					img {
						width: 85%;
						object-fit: cover;
						// max-width: 100%;
						// max-height: 100%;
					}
				}

				.text {
					font-size: 2rem;
					color: white;
					font-family: ui-monospace;
					white-space: nowrap;
				}
			}
		}

		.block-carousel {
			width: 80%;
			margin: auto;
			margin-top: 30px;

			.carousel {
				width: 44%;
				margin: 0 auto;

				// .el-carousel__item h3 {
				//   color: #475669;
				//   font-size: 14px;
				//   opacity: 0.75;
				//   line-height: 150px;
				//   margin: 0;
				// }

				// .el-carousel__item:nth-child(2n) {
				//   background-color: #99a9bf;
				// }

				// .el-carousel__item:nth-child(2n + 1) {
				//   background-color: #d3dce6;
				// }
			}

			.block-bottom {
				display: flex;
				width: 100%;
				height: 50px;
				margin: 0 auto;
				border-bottom: 1px solid #c1ae84;
				margin-top: 3rem;
				margin-bottom: 3rem;
				color: #c1ae84;
				justify-content: space-between;

				.eng {
					font-size: 25px;
					font-family: fangsong;
					font-weight: 600;
					letter-spacing: 2px;
					white-space: nowrap;
				}

				.chi {
					font-weight: bold;
					margin-left: 50px;
					white-space: nowrap;
					font-size: 1rem;
				}

				.bottom-img {
					width: 50px;
					padding-right: 20px;
				}
			}
		}

		.popular {
			width: 100%;
			background-image: url(../assets/schedule_back.png);
			background-repeat: no-repeat;
			background-size: cover;
			border-top: 2px solid #c1ae84;
			border-bottom: 2px solid #c1ae84;

			.flex-body {
				display: flex;
				width: 50%;
				height: 110px;
				margin: 0 auto;
				justify-content: space-evenly;
				align-items: end;

				.text {
					color: #c1ae84;
					text-align: center;
					font-size: 26px;
					font-family: math;
					letter-spacing: 1px;
					font-weight: 500;
					white-space: nowrap;
				}
			}

			.day {
				color: #c1ae84;
				font-size: 14px;
				font-family: ui-monospace;
				margin-top: 30px;
			}

			.container {
				width: 100%;

				.today-list {
					max-width: 1100px;
					margin: 30px auto;
					box-sizing: border-box;

					.swiper-wrapper {
						max-width: 1100px;
						transform: translateZ(0);
						position: relative;
						width: 100%;
						z-index: 1;
						display: flex;
						flex-wrap: wrap;
						transition-property: transform;
						box-sizing: content-box;
						justify-content: center;

						.slider {
							margin-left: 1em;
							width: 20%;
							margin-bottom: 0.8em;
							margin-right: 0.5em;
							flex-shrink: 0;
							height: 100%;
							position: relative;
							box-sizing: border-box;
							color: #fff;
							background: black;
							padding: 0 10px;

							.chuqin {
								height: 40px;
								line-height: 40px;
								font-size: 0.9rem;
								padding: 0;
								display: block;
								color: #969696;
								font-weight: normal;
							}

							.tupian {
								width: 100%;
								height: 250px;
								object-fit: cover;
								max-width: 100%;
								max-height: 100%;

								img {
									width: 100%;
									height: 100%;
									object-fit: cover;
									max-width: 100%;
									max-height: 100%;
								}
							}

							.slider_cast_name {
								height: 30px;
								line-height: 30px;
								font-size: 0.9rem;
								overflow: hidden;
							}

							.room_taiki {
								display: flex;
								justify-content: center;
								align-items: center;
								height: 50px;
								overflow: hidden;
								font-size: 0.7em;
								text-align: center;

								.store {
									font-size: 0.8rem;
									overflow: hidden;
									// width: 100%;
								}

								.shijian {
									background: #580742;
									margin-left: 5px;
									padding: 0 0.2vw;
									line-height: 35px;
									height: 35px;
									font-size: 0.8em;
									white-space: nowrap;
								}
							}
						}
					}
				}
			}
		}

		.pickup {
			width: 100%;

			.pickup-title {
				width: 15%;
				color: #c1ae84;
				margin: 0 auto;
				margin-top: 80px;
				margin-bottom: 50px;

				img {
					width: 100%;
					object-fit: cover;
					max-width: 100%;
					max-height: 100%;
				}

				.text {
					font-size: 30px;
					font-family: serif;
					font-weight: bold;
					margin: 22px;
				}

				.ri {
					font-size: 12px;
					font-weight: bold;
				}
			}

			.pickup-carousel {
				width: 50%;
				margin: 0 auto;

				.swiper-slide {
					border: solid 1.5px #c1ae84;
					padding-bottom: 10px;
					width: 99.9%;
					margin-right: 10px;

					.one-cast {
						padding: 20px;
						background: #000;
						position: relative;
						height: 397px;
						border: solid 1.5px #c1ae84;
						.pick_link{
							height: 100%;
							display: flex;
							align-items: center;
						}
						a {
							position: static;
							// display: block;
							color: #fff;
							box-shadow: none;
							text-decoration: none;

							.figure {
								margin: 0;
								float: left;
								margin-right: 1em !important;
								margin-bottom: 1em !important;
								width: 50%;

								img {
									height: auto;
									border: none;
									border-radius: 0;
									box-shadow: none;
									object-fit: cover;
									max-width: 100%;
									max-height: 100%;
								}
							}
							
							.profile {
								width: 50%;
								display: block;

								.new_cast {
									margin-right: 0.5em;
									margin-left: 0;
									line-height: 2;
									background: #580742;
									padding: 0.2em 1em 0.2em 1em;
									border-radius: 0;
									margin-top: 5px;
									margin-bottom: 10px;
									display: inline-table;
								}

								.cast-name {
									display: block;
									margin-right: -10px;
								}

								.cast-age {
									margin-left: 1em;
									font-size: 0.9em;
								}

								.cast-size {
									padding-top: 1em;
									border-top: 1px solid #707070;
								}

								.krc_calendar p {
									color: #969696;
									position: relative;
									font-size: 1em;
									line-height: 2;
									box-sizing: border-box;
								}
							}
						}
					}

					.one-cast:before {
						top: 0;
						content: "";
						display: inline-block;
						width: 50px;
						height: 50px;
						background-image: url(../assets/pick_top_left.png);
						background-size: contain;
						vertical-align: middle;
						position: absolute;
						left: 0;
					}

					.one-cast:after {
						top: 0;
						content: "";
						display: inline-block;
						width: 50px;
						height: 50px;
						background-image: url(../assets/pick_top_right.png);
						background-size: contain;
						vertical-align: middle;
						position: absolute;
						right: 0;
					}

					a.pick_link:before {
						content: "";
						display: inline-block;
						width: 50px;
						height: 50px;
						background-image: url("../assets/pick_bottom_left.png");
						background-size: contain;
						vertical-align: middle;
						position: absolute;
						bottom: -1px;
						left: 0px;
					}

					a.pick_link:after {
						content: "";
						display: inline-block;
						width: 50px;
						height: 50px;
						background-image: url("../assets/pick_bottom_right.png");
						background-size: contain;
						vertical-align: middle;
						position: absolute;
						bottom: -1px;
						right: 0px;
					}
				}
			}
		}

		// 新人介绍pc
		.introduce {
			color: #c1ae84;

			.flex-body {
				display: flex;
				width: 50%;
				height: 110px;
				margin: 0 auto;
				justify-content: space-evenly;
				align-items: end;

				.text {
					text-align: center;
					font-size: 26px;
					font-family: math;
					letter-spacing: 1px;
					font-weight: 500;
					white-space: nowrap;
				}
			}

			.ri {
				font-size: 12px;
				font-weight: bold;
				margin-top: 50px;
				margin-bottom: 100px;
			}

			.carousel {
				width: 70%;
				margin: 0 auto;

				.swiper {
					width: 100%;

					img {
						width: 240px;
						height: 300px;
						object-fit: cover;
						max-width: 100%;
						max-height: 100%;
					}

					.top_slider_cast_name {
						width: 98%;
						margin: 0.5em auto 0;
						box-sizing: border-box;

						.cast-name {
							margin-left: 0.1em;
						}

						.cast-age {
							margin-left: 1em;
							font-size: 0.9em;
						}

						.cast-size {
							margin-left: 0.2em;
						}
					}

					.new_cast {
						margin-right: 0.5em;
						margin-left: 0.1em;
						line-height: 1.2em;
						background: #580742;
						padding: 0.2em 0.5em;
						border-radius: 0;
						margin-top: 5px;
						margin-bottom: 10px;
						display: inline-block;
						font-size: 1em;
					}
				}
			}
		}

		.block-concept {
			width: 100%;

			box-sizing: border-box;

			.concept {
				width: 60%;
				margin: 0 auto;

				.block {
					width: 50%;

					.concept-title {
						text-align: left;

						.eng {
							font-size: 1.5rem;
							color: #c1ae84;
							font-family: fangsong;
							font-weight: bold;
							letter-spacing: 1px;
						}

						.ri {
							color: #c1ae84;
							font-size: 0.7rem;
							margin-left: 40px;
						}
					}

					.title {
						color: #fff !important;
						line-height: 3.5;
						border-bottom: 1px solid #b7a9a9;
						text-align: left;
						font-size: 1.5rem;
						white-space: nowrap;
					}

					.word-text {
						text-align: left;
						color: #fff;
						font-size: 1rem;
						line-height: 2;
					}

					.xiaozi {
						text-align: left;
						color: #fff;
						font-size: 0.7rem;
						line-height: 3;
						margin-top: 40px;
					}
				}
			}
		}

		.reservation-block {
			margin-top: 200px;
			width: 100%;

			.reservation {
				width: 60%;
				display: flex;
				margin: 0 auto;
				color: #c1ae84;
				// justify-content: space-between;
				justify-content: space-between;

				.twitter {
					width: 48%;

					.text {
						font-size: 1.5rem;
						font-family: fangsong;
						font-weight: 600;
						text-align: left;
					}

					.twitter_box {
						box-sizing: border-box;
						background-color: #fff;
						height: calc(100% - 48px);
						margin-top: 20px;
						    border-radius: 15px;
						.box_top {
							box-sizing: border-box;
							// height: 55px;
							line-height: 23px;
							padding: 12px;
							display: flex;
							justify-content: space-between;
							border-bottom: 1px solid rgb(207, 216, 222);

							a {
								text-decoration: none;
								color: inherit;
								font-weight: bold;
							}

							.box_top_a1 {
								font-size: 20px;
								color: #000;

							}

							.box_top_a2 {
								width: 120px;
								box-sizing: border-box;
								border-radius: 22.5px;
								font-size: 14px;
								background-color: #000;
								color: #fff;
								padding: 8px 16px;
							}
						}

						.box_bottom {
							overflow: auto;
							text-align: left;
							box-sizing: border-box;
							padding: 32px 34px;

							.box_bottom_text1 {
								margin-top: 30px;
								font-size: 31px;
								font-weight: bold;
								color: #000;
							}

							.box_bottom_text2 {
								margin-top: 8px;
								font-size: 15px;
								color: #536471;
								margin-bottom: 28px;
							}

							.box_bottom_btn {
								color: #fff;
								font-weight: bold;
								height: 50px;
								padding: 0 32px;
								box-sizing: border-box;
								border-radius: 25px;
								display: inline-block;
								line-height: 50px;
								background: #1d9bf0;
							}
						}
					}
				}

				.yuyue-block {
					width: 48%;
					display: inline-block;

					.title {
						text-align: left;

						.eng {
							font-size: 1.5rem;
							font-family: fangsong;
							font-weight: 600;
						}

						.ri {
							font-size: 0.8rem;
							font-family: fangsong;
							font-weight: 600;
							margin-left: 30px;
						}
					}

					.block {
						box-sizing: border-box;
						width: 100%;
						display: flex;
						line-height: 1.2;
						background-color: #580742;
						font-size: 1.8em;
						padding: 24px 24px;
						-webkit-border-radius: 3px;
						border-radius: 0;
						fill: #fff;
						text-align: center;
						-webkit-transition: all 0.3s;
						-o-transition: all 0.3s;
						transition: all 0.3s;
						border: 1px solid #c1ae84;
						justify-content: space-between;
						align-items: center;
						margin-top: 20px;

						.tel {
							font-size: 1.5rem;
							font-family: fangsong;
							font-weight: bold;
						}

						.phone {
							font-size: 1rem;
							margin-top: 20px;
						}
					}
				}
			}
		}
	}
</style>