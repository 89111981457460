 
 
//第一步导入及地址
import request from '@/utils/request'
 
// 首页查看当日排班 
export function todaylist (params){
    return request({
        url:'/sys/sysschedule/webtodaylist',
        method:'get',
        params:params

    })
}

// 一周查看当日排班 
export function weektodaylist (params){
    return request({
        url:'/sys/sysschedule/webscheduletodaylist',
        method:'get',
        params:params

    })
}

// // 一周排班 
export function weeklist (params){
    return request({
        url:'/sys/sysschedule/everyoneweeklist',
        method:'get',
        params:params

    })
}

// 查看全部排班
export function sysschedulepage (params){
    return request({
        url:`/sys/sysschedule/page?deptId=1067246875800000066`,
        method:'get',
        params:params
    })
}


// 定休日
export function dingxiuri (date){
    return request({
        url:`/sys/sysschedule/getrestday?date=${date}&deptId=1067246875800000066`,
        method:'get',
    })
}





