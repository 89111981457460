<template>
  <div>
    <!-- pc端 -->
    <div class="siteHeader_box">
			<div :class="`siteHeader ${isShow ? '' : 'siteHeader'}`">
					<div class="logopic" @click="gohome">
					<img :src=img1[0] alt="" />
				</div>
				<ul class="container">
					<li @click="menuclick(item)" v-for="item in topmenu" :key="item.id">
						<span v-if="item.title">{{item.title}} <br />{{item.subtitle?item.subtitle:''}}</span>
					</li>
				</ul>
			</div>
    </div>
    <!-- 移动端 -->
    <div :class="`phoneHeader ${isShow ? 'phoneHeader' : ''}`">
      <div class="navbar-header">
        <div class="siteHeader_logo">
          <div class="pic" @click="gohome">
            <img src="../assets/header_logo.webp" alt="" S/>
          </div>
          <div @click="navbar" class="menupic">
            <img src="../assets/1701745859700.jpg" alt="" v-if="!navshow" />
            <img src="../assets/1701746009185.jpg" alt="" v-if="navshow" />
          </div>
        </div>
        <div class="nav_box" v-if="navshow">
          <div class="nav">
            <ul>
              <li @click="gomenu(item)"  v-for="item in routemenu" :key="item.id">
                <span>{{item.title}}<span>{{item.subtitle}}</span></span>
              </li>            
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getmenu } from '@/api/menu';
import { getbanner } from '@/api/home'


export default {

  name: "HelloWorld",
  data() {
    return {
      isShow: 0, //默认不显示导航   1：显示
      navshow: false,
      routerPath: "",
      topmenu:[],
      routemenu:[],
      img1:[]
    };
  },
  mounted() {
    this.routerPath = this.$route.path;
    this.gomenus();
    this.bannerimgs()
  },
  methods: {
    gomenu(item) {
      let route = this.$route.path;
      let str = item.route.indexOf('/access');
      if(route=='/access'&&str!=-1){
        let obj = this.getUrlKey(item.route);
        if(obj.ID=='ASS'){
          document.getElementById("showD").scrollIntoView();
          this.navshow = false;
        }else{
          return
        }
      }else if(item.route==route){
        return
      } else if (item.isInternalLink == 1) {
        window.open(item.route)
      } else {
        this.$router.push(item.route);
      }    
    },
    getUrlKey(str){
      let obj = {};
      if(str && str.split('?')[1]){
        let queryArray = str.split('?')[1].split('&');
        queryArray.map((query) =>{
          let temp = query.split('=');
          if(temp.length > 1){
            obj[temp[0]] = temp[1];
          }
        })
      }
      return obj
    },
    gohome() {
      if (this.$route.path == "/") {
        return;
      } else {
        this.$router.push("/");
      }
    },
    menuclick(item) {
      if(item.route == '/TEL'){
        // window.location.href = "tel://" + '050-8883-9626';
        window.location.href = `tel:${item.title}`;
        console.log(item,`tel:${item.title}`);

      }else if(item.route == "/LINE"){
        var yourWindow = window.open();
        yourWindow.opener = null;
        yourWindow.location = item.route;
        yourWindow.target = "_blank";
      }else{
        window.location.href = item.route;
        // this.$router.push(item);
      }
    },
    navbar() {
      this.navshow = !this.navshow;
    },
    gomenus(){
      getmenu().then(({data:res})=>{
        this.topmenu = res.filter(item=>item.status==1)
        this.routemenu = res.filter(item=>item.status==0)

      })
    },
    bannerimgs(){
      let banner = []
      getbanner().then(({data:res})=>{
        banner =  res.filter(item=>item.status == 1);
        banner.forEach(item=>{
          this.img1.push (item.url)
        })

      })
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="less" scoped>
// pc端
@media (min-width: 768px) {
  .phoneHeader {
    display: none;
  }
	.siteHeader_box{
		box-sizing: border-box;
		width: 100%;
		height: 90px;
		position: fixed;background-color: rgba(0, 0, 0, 0.7);border-bottom: 2px solid;
		border-image: linear-gradient(
		  90deg,
		  rgba(172, 148, 94, 1) 0%,
		  rgba(222, 188, 118, 1) 50%,
		  rgba(201, 175, 116, 1) 100%
		);
		border-image-slice: 1;
		z-index: 99999;
		padding: 1rem 0;
		display: flex;
		justify-content: center;
	}
  .siteHeader {
    width: 80%;
    display: flex;
    justify-content: space-evenly;
    .logopic {
      height: 100%;
      cursor: pointer;
      img {
        height: auto;
        object-fit: cover;
      }
    }
  }

  .gomenu {
    display: flex;
    right: 20%;
    width: auto;
    height: 90px;
    min-height: 4em;
    position: absolute;
    top: 110px;
    z-index: 55;
    .select-router {
      color: rgb(172, 148, 94);
      border-bottom: 1px solid #ffdb8d;
      transition: width 0.36s;
    }
    .list {
      list-style: none;
      margin: 0;
      line-height: 1.4;
      font-size: 12px;
      cursor: pointer;
      li {
        text-align: center;
        font-size: 1em;
        color: #fff;
        padding-right: 2em;
      }

      .top {
        padding-bottom: 1em;
        font-weight: 200;
        font-size: 1.4em;
        white-space: nowrap;
      }
    }
    @keyframes anim1 {
      0% {
        width: 60px;
      }
      100% {
        width: 400px;
      }
    }

    ul:hover {
      color: rgb(172, 148, 94);
      border-bottom: 1px solid #ffdb8d;
      transition: width 0.36s;
    }
  }
  .container {
    width: 60%;
    float: right;
    padding-left: 0;
    list-style: none;
    display: flex;
    justify-content: space-evenly;
		margin: 0;
    li {
      color: #fff;
      width: 30%;
      text-align: center;
      position: relative;
      // line-height: 1.65em;
      color: #fff;
      background: linear-gradient(
        90deg,
        rgba(172, 148, 94, 1) 0%,
        rgba(222, 188, 118, 1) 50%,
        rgba(201, 175, 116, 1) 100%
      );
      text-align: center;
      cursor: pointer;
      span {
        display: flex;
				align-items: center;
				justify-content: center;
        height: 100%;
        font-size: 0.9em;
      }
    }

    li:first-child span:before {
      content: url(../assets/tel.png);
      position: absolute;
      left: 3%;
      top: 30%;
    }
    li:first-child span:after {
      content: url(../assets/arrow.png);
      position: absolute;
      top: 30%;
      right: 3%;
      box-sizing: border-box;
    }
    li:nth-child(2) span:before {
      content: url(../assets/header_line.png);
      position: absolute;
      left: 3%;
      top: 30%;
    }
    li:nth-child(2) span:after {
      content: url(../assets/arrows.png);
      position: absolute;
      top: 30%;
      right: 3%;
    }
    li:last-child span:before {
      content: url(../assets/mail.png);
      position: absolute;
      left: 3%;
      top: 30%;
    }
    li:last-child span:after {
      content: url(../assets/arrow2.png);
      position: absolute;
      top: 30%;
      right: 3%;
    }
  }
}

// 移动端
@media (max-width: 768px) {
  .siteHeader {
    display: none;
  }
  .phoneHeader {
    background-color: rgba(0, 0, 0, 0.7);
    height: 90px;
    position: fixed;
    z-index: 56;
    border-bottom: 2px solid;
    border-image: linear-gradient(
      90deg,
      rgba(172, 148, 94, 1) 0%,
      rgba(222, 188, 118, 1) 50%,
      rgba(201, 175, 116, 1) 100%
    );
    border-image-slice: 1;
    width: 100%;
    .navbar-header {
      float: none;
      width: 100%;
      .siteHeader_logo {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 20px;
        width: 100%;
        box-sizing: border-box;
        .pic {
          width: 250px;
          img {
            width: 100%;
            object-fit: cover;
            max-width: 100%;
            max-height: 100%;
          }
        }
        .menupic {
          width: 30px;
          height: 30px;
          border: #333 solid 1px;
          background: #333;
          z-index: 999999;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            max-width: 100%;
            max-height: 100%;
          }
        }
      }

      .nav_box {
        height: 100vh;
        overflow: auto;
        padding-bottom: 8rem;
        background-color: #fff;
        border-bottom: 1px solid #e5e5e5;
        position: fixed;
        z-index: 2000;
        width: 100%;
        height: 100%;
        display: block;
        overflow: scroll;
        top: 0;
        transition: all 0.5s ease-out;
        padding: 90px 10px 45px;
        .nav {
          margin-bottom: 1.5em;
        }
        .nav ul {
          border-top: 1px solid #ccc;
          position: relative;
          text-align: left;
          padding: 0;
        }
        .nav ul li {
          list-style: none;
          line-height: 1.65;
          position: relative;
          border-bottom: 1px solid #ccc;
          padding: 0.7em 0.5em;
        }
        .nav ul li span {
          color: #333;
          position: relative;
          overflow: hidden;
        }
        // .nav ul li a span {
        //     margin-left: 1em;
        // }
      }
    }
  }
}
</style>
