import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import SmoothScroll from 'vue-smooth-scroll'
// import '@assets/fonts/'


Vue.use(ElementUI);
Vue.use(SmoothScroll)
Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

router.afterEach((to,from,next)=>{
  window.scrollTo(0,0);
})