import request from '@/utils/request'
import moment from "moment";


// 查看轮播图
export function getbanner (){
    return request({
        url:`/sys/carouselimages/list?deptId=1067246875800000066`,
        method:'get',
    })
}


// 查看菜单
export function getmenuList (){
    return request({
        url:`/sys/menulist/list?deptId=1067246875800000066`,
        method:'get',
    })
}




// // 查看预约时段
export function gettime (){
    let today = moment().format("YYYY-MM-DD");
    if(new Date().getHours()<7){
        today=moment().subtract(1, "days").format("YYYY-MM-DD");
    }
    console.log(today);
    return request({
        url:`/sys/sysperformerreservation/waittimelist?deptId=1067246875800000066&date=${today}`,
        method:'get',
    })
}
