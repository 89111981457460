import request from '@/utils/request'


// 查看全部文本
export function richtext (){
    return request({
        url:`/sys/richtext/list?deptId=1067246875800000066`,
        method:'get',
    })
}

// 查看全部费用
export function getfee (){
    return request({
        url:`/sys/cost/list?deptId=1067246875800000066`,
        method:'get',
    })
}
