import request from '@/utils/request'




// 预约申请
export function savepost (data){
    return request({
        url:`/sys/sysreservation?deptId=1067246875800000066`,
        method:'post',
        data:data
    })
}

// 预约申请
export function save (data){
    return request({
        url:`/sys/sysperformerreservation?deptId=1067246875800000066`,
        method:'post',
        data:data
    })
}


// 预约申请获取验证码
export function getcode (data){
    return request({
        url:'/sys/sysperformerreservation/getsms',
        method:'post',
        headers:{
            "Accept-Languag":"ja-JP",
            "Content-Type":"multipart/form-data"
        },
        data:data
    })
}


// 获取已预约时间段
export function timeParagraph (params){
    params['deptId'] = '1067246875800000066';//?deptId=1067246875800000066
    return request({
        url:`/sys/sysperformerreservation/list`,
        method:'get',
        params:params
    })
}
